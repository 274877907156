<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Agregar Responsable</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchResponsibles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(access.fullName)="data">
            <b-media vertical-align="center">
                <template #aside>
                    <b-avatar
                        size="32"
                        :src="avatarSrc(data.item.access.photo)"
                        :text="avatarText(data.item.access.fullName)"
                        variant="light-success"
                        :to="{ name: 'users-edit', params: { id: data.item.id } }"
                    />
                </template>
                <b-link
                    :to="{ name: 'responsibles-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap">
                    {{ data.item.access.fullName }}
                </b-link>
            </b-media>
        </template>
        
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'responsibles-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteUser(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {useToast} from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import {onUnmounted, ref} from '@vue/composition-api';
import {avatarText} from '@core/utils/filter';
import useResponsiblesList from './useResponsiblesList'
import responsibleStoreModule from '../responsibleStoreModule'
import UserListAddNew from './ResponsibleListAddNew.vue'

export default {
  components: {
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    avatarSrc (img){
        if (img){
            if (img.includes('http')){
                return img;
            } else {
                if (process.env.VUE_APP_ENVIROMENT == 'development'){
                    return require(`@/assets/images/modules/responsibles/${img}`);
                } else {
                    return `${process.env.VUE_APP_FILES}/modules/responsibles/${img}`;
                }
            }
        } else {
            return null;
        }
    }
  },
  setup() {
    // Use toast
    const toast = useToast();
    
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, responsibleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)


    const {
      fetchResponsibles,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      
    } = useResponsiblesList()

    const deleteUser = (id) => {
        store.dispatch('app-user/deleteResponsible', { id })
        .then( (response) => {
            refetchData();
            toast({
                component: ToastificationContent,
                props: {
                    title: response.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            });
        })
        .catch( () => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error al eliminar al responsable',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            });
        });        
    };
    
    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchResponsibles,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
      deleteUser,
    }
  },
}
</script>

<style lang="scss" scoped>
    .media {
        align-items: center;
    }
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
