import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useResponsiblesList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'access.fullName', sortable: true, label: 'Responsable' },
    { key: 'documentNumber', sortable: true, label: 'Número de documento' },
    { key: 'access.email', sortable: true, label: 'Correo' },
    { key: 'access.phone', sortable: true, label: 'Teléfono' },
    { key: 'customer.name', sortable: true, label: 'Cliente' },
    { key: 'position', sortable: true, label: 'Cargo' },
    { key: 'actions', label: 'Opciones' }
];

  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchResponsibles = (ctx, callback) => {
    store
      .dispatch('app-user/fetchResponsibles', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { responsibles, total } = response.data;
        callback(responsibles);
        totalUsers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error al obtener la lista de responsables',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchResponsibles,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
  }
}
